.banner {
    width: 100vw;
    height: 100vh;
}

.banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
